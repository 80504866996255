import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const getHtml = (htmlTitle, htmlBody) => {
  const fullHtml = `
      <head>  
        <style>
            p {
                margin-bottom: 1.2rem;
            }
            ul, ol {
              margin-left: 1.5rem;
            }
            h2 {
              font-weight: 500;
              line-height: 1.3;
              font-weight: bold;
              font-size: 1.8rem;
              margin-bottom: 1rem;
            }
            h3 {
              font-weight: 500;
              line-height: 1.3;
              font-weight: bold;
              font-size: 1.6rem;
              margin-bottom: 1rem;
            }
            h4 {
              font-weight: 500;
              line-height: 1.3;
              font-weight: bold;
              font-size: 1.4rem;
              margin-bottom: 1rem;
            }
            h5 {
              font-weight: 500;
              line-height: 1.3;
              font-weight: bold;
              font-size: 1.2rem;
              margin-bottom: 1rem;
            }
            h6 {
              font-weight: 500;
              line-height: 1.3;
              font-weight: bold;
              margin-bottom: 1rem;
            }
        </style>
      </head>
      <h1 style="text-align:center; font-weight: bold; font-size: 2rem; margin: 10px;">${htmlTitle}</h1>
      <p style="margin: 10px;">${htmlBody}</p>
  `;

  return fullHtml;
};

export const getArticleData = (selectedContentTypeEntry) => {
  const isArticleContentType =
  selectedContentTypeEntry?.sys?.contentType?.sys?.id === 'article'
    
  const options = {
    renderNode: {
      'embedded-asset-block': (node) =>
        `<img alt="" src="${node.data.target.fields.file.url}"/>`,
    },
  };
  const html = documentToHtmlString(
    isArticleContentType ? selectedContentTypeEntry.fields.body : '',
    options
  );

  return getHtml(
    isArticleContentType
      ? selectedContentTypeEntry.fields.title
      : '',
    html
  );
};
